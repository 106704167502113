/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { FC, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { QuestionType, QuestionsOutputMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button, Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Confirm } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/confirm';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { ImageModal } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/image-modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { MAX_PROGRESS } from '../../../../../constants/course-stage.constants';
import {
  DECLENSION_OPTIONS_MIN,
  DECLENSION_OPTIONS_SEC,
  MODAL_OPTIONS,
  secondsInMinute,
} from '../../../../../constants/timer.constants';
import { CourseStageQuery } from '../../../../../models/entities/builder-course.models';
import { IWarningModal } from '../../../../../models/entities/stages.models';
import { ITestingQuestionDto } from '../../../../../models/entities/testing.models';
import { BrowserRoute } from '../../../../../routes/browser.routes';
import { declensionByNumber } from '../../../../../shared/declensionByNumber';
import { getValuePercent } from '../../../../../shared/get-value-percent';
import { useDemoTimer } from '../../../../../shared/use-demo-timer';
import { getQuestionAnswer } from '../../../../components/answer-preview/utils';
import { AlarmIcon } from '../../../../components/icons/alarm';
import { DoneIcon } from '../../../../components/icons/done';
import { IPaginationScrollItem, PaginationScroll } from '../../../../components/pagination-scroll';
import { QuestionPreview } from '../../../../components/question-preview';
import { useStyles } from '../../../course-stage/use-styles';
import { confirmMessages, getAnswersCount } from '../../../course-stage/utils';
import { demoAssessment } from '../../demoAssessment';
import { COURSE_MOCK_DATA } from '../../mock-data';
import { TEST_STEPS, QUESTION_STEPS, TEST_FINISH_STEPS } from '../../steps';

const getRemainingTime = (seconds: number): string => {
  if (seconds > secondsInMinute) {
    const remainingTime = declensionByNumber(Math.round(seconds / secondsInMinute), DECLENSION_OPTIONS_MIN);
    return `У вас осталось ${remainingTime} для завершения оценки!`;
  }
  return `Ваше время закончится через ${declensionByNumber(seconds, DECLENSION_OPTIONS_SEC)}.`;
};

export const DemoAssessmentStagePage: FC = observer(() => {
  const [search, setSearchParams] = useSearchParams();
  const classes = useStyles();
  const confirm = useModalState();
  const imagePreview = useModalState<string | undefined>();
  const timerModal = useModalState();
  const navigate = useNavigate();
  const { setSteps, setCurrentStep } = useTour();
  const testingTimer = useRef(null);
  const [confirmMessage, setConfirmMessage] = useState<string>(confirmMessages.default);
  const [modalOptions, setModalOptions] = useState<IWarningModal>(null);
  const { model, onlyQuestions, userTest, userAnswers, setUserTest, setUserAnswers, startStage } = demoAssessment;

  const questionNumber = Number(search.get(CourseStageQuery.question) ?? 1);


  const isOneByOneMode = model.test_options.output === QuestionsOutputMode.byOne;

  useEffect(() => {
    if (!onlyQuestions) {
      startStage();
      setCurrentStep(0);
      setSteps(TEST_STEPS);

      return;
    }

    setSteps(QUESTION_STEPS[userTest[0].questions[questionNumber - 1].type]);
    setCurrentStep(0);
  }, [questionNumber, onlyQuestions]);

  const { timer, timeLeft } = useDemoTimer({
    timeLeft: parseInt(userTest[0]?.timeLeft.toFixed()),
    finish: () => {
      handleCloseTimerModal();
      navigate(BrowserRoute.demoAssessment);
    },
  });

  useEffect(() => {
    const options = MODAL_OPTIONS[timeLeft];

    if (options) {
      setModalOptions(options);
    }
  }, [timeLeft]);

  const pagination = useMemo(
    () => userTest[0]?.questions.map((el, i) => ({
      label: `${i + 1}`,
      href: el['question-id'],
      // @ts-ignore
      answered: Boolean(getQuestionAnswer(el).length),
    })),
    [userTest],
  );

  const progress = useMemo(() => {
    const questions = userTest[0]?.questions || [];
    const questionsCount = questions.length;
    const answersCount = getAnswersCount(questions as ITestingQuestionDto[]);

    return getValuePercent(answersCount, questionsCount);
  }, [userTest]);

  const handleSetCurrentQuestion = (value: string): void => {
    if (isOneByOneMode) {
      search.set(CourseStageQuery.question, value ?? '');
      setSearchParams(search.toString());
    }
  };

  const handleSetPrevQuestion = (): void => {
    if (questionNumber > 1) {
      handleSetCurrentQuestion(String(questionNumber - 1));
    }
  };

  const handleSetNextQuestion = (): void => {
    if (questionNumber < userTest[0].questions.length) {
      handleSetCurrentQuestion(String(questionNumber + 1));
    }
  };

  const handleClickPaginationItem = ({ label }): void => {
    handleSetCurrentQuestion(label);
  };

  const getOptionProps = useCallback(
    (el: IPaginationScrollItem): HTMLAttributes<HTMLDivElement> => {
      const className = cn({
        [classes.answered]: el.answered,
        [classes.active]: isOneByOneMode && el.label === String(questionNumber),
      });

      return { className };
    },
    [classes, questionNumber, isOneByOneMode],
  );

  const handleFinishTest = (): void => {
    sessionStorage.setItem('userTest', JSON.stringify(userAnswers));
    const courseStagesLinks = COURSE_MOCK_DATA.map((course) => course.stages.map((stage) => stage.href));
    const demoAnswersPage = courseStagesLinks[0][0].toString();
    navigate(BrowserRoute.demoAssessmentStageAnswers(demoAnswersPage));
  };

  const handleClose = (): void => {
    navigate(BrowserRoute.demoAssessment);
  };

  const onClickFinish = (): void => {
    setConfirmMessage(confirmMessages.default);
    confirm.open();
  };

  useEffect(() => {
    if (modalOptions) {
      timerModal.open();
    }
  }, [modalOptions, userTest]);

  useEffect(() => {
    if (progress === 100) {
      setSteps(TEST_FINISH_STEPS);
      setCurrentStep(0);
    }
  }, [progress]);

  const handleCloseTimerModal = (): void => {
    setModalOptions(null);
    timerModal.close();
  };

  const handleCloseConfirm = (): void => {
    confirm.close();
  };

  const updatedTest = useMemo(() => {
    const updateUserTest = [...userTest];
    updateUserTest.map((item) => {
      item.questions.map((question) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!question.hasOwnProperty('user-answer-id')) {
          question['user-answer-id'] = '';
        }
      });
    });

    setUserAnswers(updateUserTest[0]);
    return updateUserTest;
  }, []);

  const handleUpdateAnswer = ({ questionId, answerId, type }): void => {
    const updateUserTest = [...updatedTest];
    updateUserTest.map((item) => {
      item.questions.map((question) => {
        if (question['question-id'] === questionId) {
          let updatedAnswerId: string | string[] | Record<number, string> = answerId;
          if (type === QuestionType.plural) {
            const test = Object.values(question['user-answer-id'] || []);
            if (test.includes(answerId)) {
              updatedAnswerId = test.filter((el) => el !== answerId);
            }
            else {
              updatedAnswerId = [...test, answerId];
            }
          }
          question['user-answer-id'] = updatedAnswerId;
        }
      });
    });

    setUserTest(updateUserTest);
    setUserAnswers(updateUserTest[0]);
  };

  return (
    <>
      <div
        className={classes.root}
        onMouseMove={() => clearTimeout(testingTimer.current)}
      >
        <h1 className={classes.title}>Оценка #1</h1>

        <PaginationScroll
          containerId="questions-scroll"
          options={pagination}
          getOptionProps={getOptionProps}
          onClickItem={handleClickPaginationItem}
          className={classes.pagination}
        />

        <div className={classes.content}>
          <div className={classes.questions} id="questions-scroll">
            {isOneByOneMode ? (
              <div data-tour="test-step-2" className={cn(classes.questionList, 'scrollbar')}>
                <QuestionPreview
                  key={questionNumber - 1}
                  question={userTest[0].questions[questionNumber - 1] as ITestingQuestionDto}
                  index={questionNumber - 1}
                  onUpdate={handleUpdateAnswer}
                  onImagePreview={(src): void => imagePreview.open(src)}
                  prevQuestion={handleSetPrevQuestion}
                  nextQuestion={handleSetNextQuestion}
                  isOneByOneMode={isOneByOneMode}
                  lastItemIndex={userTest[0].questions.length - 1}
                />
              </div>
            ) : (
              <div data-tour="test-step-1" className={cn(classes.questionList, 'scrollbar')}>
                {userTest[0].questions.map((question, i) => (
                  <QuestionPreview
                    key={`question-${question['question-id']}`}
                    question={question as ITestingQuestionDto}
                    index={i}
                    onUpdate={handleUpdateAnswer}
                    onImagePreview={(src): void => imagePreview.open(src)}
                  />
                ))}
              </div>
            )}
          </div>

          <div className={classes.actions}>
            <Paper data-tour="test-step-5" className={classes.progress}>
              <div className={classes.progressBar}>
                <div className={classes.percent} style={{ width: `${progress}%` }} />
              </div>
              <div className={classes.fontSize12}>{progress}%</div>
            </Paper>

            <Paper className={classes.buttons}>
              <div>
                <button data-tour="test-step-9" className={classes.btn} type="button" onClick={handleClose}>
                  <CloseIcon className={classes.btnIcon} />
                  <p>Закрыть</p>
                </button>
              </div>

              <button
                data-tour="test-step-10"
                className={cn(classes.btn, { [classes.animate]: progress === MAX_PROGRESS })}
                type="button"
                onClick={onClickFinish}
              >
                <DoneIcon className={classes.btnIcon} />
                <p data-tour="test-step-11">Завершить оценку</p>
              </button>
            </Paper>

            <Paper data-tour="test-step-6" className={classes.timer}>
              {timer || '00:00:00'}
            </Paper>
          </div>
        </div>

        <Confirm
          {...confirm.props}
          className={classes.confirm}
          title="Внимание!"
          summary={<p dangerouslySetInnerHTML={{ __html: confirmMessage }} />}
          confirmLabel="Завершить оценку!"
          onConfirm={handleFinishTest}
          onCancel={handleCloseConfirm}
          onClose={handleCloseConfirm}
        />
        <ImageModal {...imagePreview.props} size="xl" />

        <Modal {...timerModal.props} onClose={handleCloseTimerModal} color="orange" className={classes.modal}>
          <CloseIcon className={classes.closeIcon} onClick={handleCloseTimerModal} />
          <AlarmIcon color="#F7B055" className={classes.confirmIcon} />
          <div className={classes.confirmContent}>
            <p className={classes.fontSize24}>Внимание!</p>
            <p>{getRemainingTime(timeLeft)} {modalOptions?.message}</p>
          </div>
          <Button
            variant="warning"
            size="sm"
            onClick={modalOptions?.action === 'finish' ? handleFinishTest : handleCloseTimerModal}
          >
            {modalOptions?.btnLabel}
          </Button>
        </Modal>
      </div>
    </>
  );
});
