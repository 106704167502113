import { QuestionsOutputMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';
import { STAGE_STATUSES } from '../../../constants/builder-course.constants';
import { COURSE_MOCK_DATA, MODEL_MOCK_DATA } from './mock-data';
import { IMockData, QUESTIONS_MOCK_DATA } from './pages/demo-assessment-stage/mock-data';

class DemoAssessment {

  public onlyQuestions = false;
  public model = MODEL_MOCK_DATA;
  public courses = COURSE_MOCK_DATA;
  public userTest = QUESTIONS_MOCK_DATA;
  public userAnswers: IMockData | null = null;
  public demoTimer: number = null;

  constructor() {
    makeAutoObservable(this);
  }

  public startStage = () => {
    this.courses[0].stages[0] = {
      ...this.courses[0].stages[0],
      ...STAGE_STATUSES.proceed,
      timeLeft: 60 * 45,
    };
  };

  public setByOneOutput = (isByOne = true) => {
    this.model.test_options.output = isByOne ? QuestionsOutputMode.byOne : QuestionsOutputMode.list;
  };

  public enableOnlyQuestions = () => {
    this.onlyQuestions = true;
  };

  public disableOnlyQuestions = () => {
    this.onlyQuestions = false;
  };

  public setUserTest = (newTest: IMockData[]) => {
    this.userTest = newTest;
  };

  public setDemoTimer = (time: number) => {
    this.userTest[0].timeLeft = time;
  };

  public setUserAnswers = (newAnswers: IMockData | null) => {
    this.userAnswers = newAnswers;
  };

  // Метод для сброса состояния демоверсии
  public resetDemoState = () => {
    this.model = MODEL_MOCK_DATA;
    this.courses = COURSE_MOCK_DATA;
    this.userTest = QUESTIONS_MOCK_DATA;
    this.userAnswers = null;
    this.onlyQuestions = false;
    this.setByOneOutput(false);
  };

  get getUserQuestions() {
    return this.userAnswers?.questions;
  }

  get getDemoTimer() {
    return this.demoTimer;
  }

  get getStageStatus() {
    return this.courses[0].stages[0].status;
  }

}

export const demoAssessment = new DemoAssessment();
